<template>
  <div class="flex flex-col space-y-2" :data-cy="`branding-${template.id}`">
    <div v-if="bgImage" class="rounded image-template h-[64px] w-[90px] p-2">
      <div class="bg-white h-[6px] w-[20px] rounded-[1px]" />
      <div class="bg-white h-[3px] w-[30px] rounded-[1px] mt-4" />
      <div class="bg-white h-[3px] w-[40px] rounded-[1px] mt-1" />
      <div class="flex items-center space-x-1 mt-3">
        <div class="bg-login-bg h-[5px] w-[5px] rounded-full" />
        <div class="bg-white h-[1px] w-[11px] rounded-[1px]" />
      </div>
    </div>
    <icon-base
      v-else
      :icon="IconEmailBrandingPreview"
      width="90"
      height="64"
      class="rounded"
      :style="{
        color: template.header_primary_color || ''
      }"
    />

    <label class="flex cursor-pointer space-x-2">
      <input
        type="radio"
        :value="template.id"
        class="cursor-pointer"
        v-model="brandingModel"
      />
      <span class="text-headline -mt-0-25 break-all">
        {{ template.template_name }}
      </span>
    </label>
  </div>
</template>

<script setup lang="ts">
import { computed, type PropType } from "vue";
import type { IEmailCustomization } from "@/models/clients";
import IconBase from "@/components/ui/IconBase.vue";
import IconEmailBrandingPreview from "@/components/icons/IconEmailBrandingPreview.vue";

const props = defineProps({
  template: {
    type: Object as PropType<IEmailCustomization>,
    required: true
  }
});

const brandingModel = defineModel<number | null>();

const bgImage = computed(() => {
  return props.template.header_image
    ? `url(${props.template.header_image})`
    : "";
});
</script>
<style scoped>
.image-template {
  background-image: v-bind(bgImage);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
